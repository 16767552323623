import { useAppContext } from '@/core/App/AppContext'
import { getToken } from '@/utils/auth'
import { GraphQLClient } from 'graphql-request'
import { useCallback, useEffect, useState } from 'react'
import { graphqlWebClient } from './graphqlClient'

const client = new GraphQLClient(
  `${process.env.APP_MAGENTO_ADMIN_URL}/graphql`,
  {
    method: 'GET',
  }
)
export const clientPost = new GraphQLClient(
  `${process.env.APP_MAGENTO_ADMIN_URL}/graphql`,
  {
    method: 'POST',
  }
)
// clientPost.request()
const mu_client = new GraphQLClient(
  `${process.env.APP_MAGENTO_ADMIN_URL}/graphql`,
  {
    method: 'POST',
  }
)

type IGraphQueryInterface = [
  {
    data: any
    error: any
    loading: boolean
  },
  (e?: any) => Promise<any>,
  any?
]

const useGraphQLQuery = (
  query,
  parameters = null,
  { immediate = false, method = 'GET', authorized = false, fn = (d) => d }
) => {
  const [data, setData] = useState({
    data: null,
    error: null,
    loading: immediate,
  })

  const request = useCallback(
    (parameters: any) => {
      const headers = {}
      if (authorized) {
        const token = getToken()
        headers['authorization'] = `Bearer ${token}`
      }

      return (authorized || method == 'POST' ? clientPost : client)
        .request(query, parameters, headers)
        .then((data) => {
          setData({
            data: fn(data),
            error: null,
            loading: false,
          })
          return fn(data)
        })
        .catch((error) => {
          setData({
            data: null,
            error,
            loading: false,
          })
          return error.response
        })
    },
    [query]
  )

  useEffect(() => {
    if (immediate) {
      request(parameters)
    }
  }, [])
  return [
    data,
    request,
    {
      reset: () => {
        setData({
          data: null,
          error: null,
          loading: false,
        })
      },
    },
  ] as IGraphQueryInterface
}

export const useGetGraphQLQuery = (
  query,
  parameters = null,
  { immediate = false, authorized = false, fn = (d) => d }
) => {
  const [data, setData] = useState({
    data: null,
    error: null,
    loading: immediate,
  })

  const request = useCallback(
    (parameters: any) => {
      const headers = {}
      if (authorized) {
        const token = getToken()
        headers['authorization'] = `Bearer ${token}`
      }

      return client
        .request(query, parameters, headers)
        .then((data) => {
          setData({
            data: fn(data),
            error: null,
            loading: false,
          })
          return fn(data)
        })
        .catch((error) => {
          setData({
            data: null,
            error,
            loading: false,
          })
          return error.response
        })
    },
    [query]
  )

  useEffect(() => {
    if (immediate) {
      request(parameters)
    }
  }, [])
  return [
    data,
    request,
    {
      reset: () => {
        setData({
          data: null,
          error: null,
          loading: false,
        })
      },
    },
  ] as IGraphQueryInterface
}

export const useGraphQLMutation = (
  query,
  parameters = null,
  { immediate = false, authorized = false, fn = (d) => d }
) => {
  const [data, setData] = useState({
    data: null,
    error: null,
    loading: false,
  })
  const [{ mitter }] = useAppContext()
  const headers = {}
  if (authorized) {
    const token = getToken()
    headers['authorization'] = `Bearer ${token}`
  }
  const request = useCallback(
    (parameters: any) => {
      return mu_client
        .request(query, parameters, headers)
        .then((data) => {
          setData({
            data,
            error: null,
            loading: false,
          })
        })
        .catch((error) => {
          setData({
            data: null,
            error,
            loading: false,
          })
        })
    },
    [query]
  )

  return [data, request] as IGraphQueryInterface
}

export default useGraphQLQuery
